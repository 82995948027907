import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../components/PageHeaderBlock";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import ArticleWidget from "../components/widgets/ArticleWidget";
import ArticleWidget2 from "../components/widgets/ArticleWidget2";

export default function MobileFriendlyAlgoritmOtPoiskovojSistemyGoogle() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('post7Title')} description={t('post7Description')} />
            <PageHeaderBlock h1="post7H1" />
            <Container fluid className="px-flex">
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7} className="blog-post">
                        <StaticImage
                            src="../images/posts/post7/mobile-friendly-algoritm-ot-poiskovoj-sistemy-google.jpg"
                            width={1700}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post7H1')}
                            className="blog-post__img w-100 mb-2 mb-xl-3 mb-xxl-4"
                            placeholder="blurred"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post7Text')}} />
                        <Carousel className="mb-2 mb-xl-3 mb-xxl-4 text-center">
                            <Carousel.Item>
                                <StaticImage
                                    src="../images/posts/post7/mobile-friendly-algoritm-ot-poiskovoj-sistemy-google-1.jpg"
                                    width={1358}
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post7Text1ImgAlt')}
                                    title={t('post7Text1ImgTitle')}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <StaticImage
                                    src="../images/posts/post7/rasstoyanie-mezhdu-ssylkami-dlya-prohozhdeniya-gugl-mobajl-frendli-testa.png"
                                    width={1358}
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post7Text1ImgTitle')}
                                    title={t('post7Text1ImgAlt')}
                                />
                            </Carousel.Item>
                        </Carousel>
                        <div dangerouslySetInnerHTML={{__html: t('post7Text1')}} />
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2 /></Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;